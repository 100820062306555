import React from "react"
import { StaticImage } from "gatsby-plugin-image";

const WorkshopImage = props => {

  const { eventType } = props
  return (
    <>
      {eventType === 'qt-level-1' ?
        <StaticImage
          src="../images/workshops/quantum-touch/Level-1-becoming-energized-min.png"
          alt="Quantum-Touch Level 1 Becoming Energized Workshop Image"
        /> : eventType === 'qt-level-4' ?
          <StaticImage
            src="../images/workshops/quantum-touch/Level-4-becoming-heart-conscious-min.png"
            alt="Quantum-Touch Level 4 Becoming Heart Conscious Workshop Image"
          /> : eventType === 'qt-supercharging' ?
            <StaticImage
              src="../images/workshops/quantum-touch/supercharging-min.jpg"
              alt="Quantum-Touch Supercharging"
            /> : eventType === 'qt-level-3' ?
              <StaticImage
                src="../images/workshops/quantum-touch/Level-3-becoming-whole-min.png"
                alt="Quantum-Touch Self Created Health"
              /> : eventType === 'qt-energy-boost' ?
              <StaticImage
                src="../images/workshops/quantum-touch/energy-boost-mini-min.jpg"
                alt="Quantum-Touch Energy Boost"
              />
              : eventType === 'qt-becoming-more' ?
              <StaticImage
                src="../images/workshops/quantum-touch/becoming-more-richard-gordon-min.jpg"
                alt="Quantum-Touch Becoming More"
              />
              : eventType === 'qt-becoming-miraculous' ?
              <StaticImage
                src="../images/workshops/quantum-touch/Level-5-becoming-miraculous-min.png"
                alt="Quantum-Touch Level 5 - Becoming Miraculous"
              />
              :
                <StaticImage
                  src="../images/programs/quantum-touch/level-1-min.jpg"
                  alt="Quantum-Touch"
                />
      }
    </>
  )
}

export default WorkshopImage
